import { useStaticQuery, graphql } from "gatsby";
export const useMeetOurFundersPromoSlots = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Meet Our Funders" } }) {
        asignPromoSlots {
          heading
          copy
          choosePromoSlots {
            ... on WpPromoSlot {
              id
              promoSlotsFields {
                promoSlotsGroup {
                  chooseBlogPost {
                    ... on WpPost {
                      id
                      excerpt
                      content
                      title
                      date(formatString: "MMMM DD, YYYY")
                      featuredImage {
                        node {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                height: 500
                                width: 600
                                transformOptions: { cropFocus: CENTER }
                              )
                            }
                          }
                          altText
                        }
                      }
                      uri
                      categories {
                        nodes {
                          name
                          uri
                          id
                        }
                      }
                      author {
                        node {
                          firstName
                          lastName
                          nicename
                          nickname
                          uri
                        }
                      }
                    }
                  }
                  choosePage {
                    ... on WpPage {
                      id
                      featuredImage {
                        node {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                height: 500
                                width: 600
                                transformOptions: { cropFocus: CENTER }
                              )
                            }
                          }
                          altText
                        }
                      }
                    }
                  }
                  promoSlotType
                  content {
                    copy
                    link {
                      target
                      title
                      url
                    }
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            transformOptions: { cropFocus: CENTER }
                          )
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.asignPromoSlots;
};
