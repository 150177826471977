import { useStaticQuery, graphql } from "gatsby";

export const useMeetOurFundersHeadingAndCopies = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Meet Our Funders" } }) {
        meetOurFunders {
          foundingPartnershipsCopy
          managingPartnershipsCopy
          sustainingPartnershipsCopy
          meetOurFundersHeadingSection {
            copy
            heading
          }
        }
      }
    }
  `);
  return data.wpPage.meetOurFunders;
};
