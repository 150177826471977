import React from "react";
import { useMeetOurFundersPromoSlots } from "../hooks/useMeetOurFunders/useMeetOurFundersPromoSlots";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import PartnersSection from "../components/MeetOurPartners/PartnersSection";
import PromoSlotsSection from "../components/PromoSlotsSection/PromoSlotsSection";
import Footer from "../components/Footer/Footer";

const MeetOurPartners = () => {
  const promoSlotsData = useMeetOurFundersPromoSlots();
  return (
    <Layout>
      <Header />
      <PartnersSection />
      <PromoSlotsSection data={promoSlotsData} />
      <Footer />
    </Layout>
  );
};

export default MeetOurPartners;
