import React, { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { useMeetOurFundersHeadingAndCopies } from "../../hooks/useMeetOurFunders/useMeetOurFundersHeadingAndCopies";
// import { useWhoWeArePartnersSectionPartnerFoundingPeople } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersFoundingPeople";
// import { useWhoWeArePartnersSectionPartnerFoundingComp } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersFoundingComp";
// import { useWhoWeArePartnersSectionPartnerManagingPeople } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersManagingPeople";
// import { useWhoWeArePartnersSectionPartnerManagingComp } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersManagingComp";
// import { useWhoWeArePartnersSectionPartnerSustainingPeople } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersSustainingPeople";
// import { useWhoWeArePartnersSectionPartnerSustainingComp } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersSustainingComp";
import { useWhoWeArePartnersSectionPartnersUncategorizedComp } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersUncategorizedComp";
import { useWhoWeArePartnersSectionPartnersUncategorizedPeople } from "../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersUncategorizedPeople";

// import IsrLabel from "../IsrLabel/IsrLabel";
import LogoToVisit from "../LogoToVisit/LogoToVisit";
import BioCard from "../BioCard/BioCard";
import Spacer from "../Spacer/Spacer";
import BioModal from "../BioModal/BioModal";
import "./PartnersSection.scss";

const PartnersSection = () => {
  const {
    meetOurFundersHeadingSection,
    // sustainingPartnershipsCopy,
    // managingPartnershipsCopy,
    // foundingPartnershipsCopy,
  } = useMeetOurFundersHeadingAndCopies();

  const uncategorizedPartnershipsPeople =
    useWhoWeArePartnersSectionPartnersUncategorizedPeople();
  const uncategorizedPartnershipsComp =
    useWhoWeArePartnersSectionPartnersUncategorizedComp();

  // const foundingPartnershipsPeople =
  //   useWhoWeArePartnersSectionPartnerFoundingPeople();
  // const foundingPartnershipsComp =
  //   useWhoWeArePartnersSectionPartnerFoundingComp();

  // const managingPartnershipsPeople =
  //   useWhoWeArePartnersSectionPartnerManagingPeople();
  // const managingPartnershipsComp =
  //   useWhoWeArePartnersSectionPartnerManagingComp();

  // const sustainingPartnershipsPeople =
  //   useWhoWeArePartnersSectionPartnerSustainingPeople();
  // const sustainingPartnershipsComp =
  //   useWhoWeArePartnersSectionPartnerSustainingComp();

  const [bioModalOpen, setModalOpen] = useState(false);
  const [selectedMemberToRead, setSelectedMemberToRead] = useState(null);

  const handleReadBioClick = async (member) => {
    await setSelectedMemberToRead(member);
    setModalOpen(true);
  };
  return (
    <>
      <section className=" fundersSection">
        <div className="container">
          <Spacer size={100} mobileSize={50} />
          {meetOurFundersHeadingSection.heading && (
            <h1 className="h2">{meetOurFundersHeadingSection.heading}</h1>
          )}
          <hr size="3" className="divider" />

          {meetOurFundersHeadingSection.copy && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: meetOurFundersHeadingSection.copy,
              }}
            />
          )}

          <Spacer size={100} mobileSize={50} />
          <hr size="3" />
          <Spacer size={100} mobileSize={50} />
          <div className="foundingPartnershipsContainer">
            {uncategorizedPartnershipsComp &&
              uncategorizedPartnershipsPeople &&
              (uncategorizedPartnershipsComp.length === 0 ||
                uncategorizedPartnershipsPeople.length === 0) && (
                <h3 className="no-data">
                  Founding Partners
                </h3>
              )}
            <div className="row logoToVisitContainer">
              {uncategorizedPartnershipsComp &&
                uncategorizedPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrPartner.picture}
                    url={item.isrPartner.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className="row membersContainer">
              {uncategorizedPartnershipsPeople &&
                uncategorizedPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrPartner}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrPartner.picture !== null}
                  />
                ))}
            </div>
          </div>
          {/* <div className="foundingPartnershipsContainer">
            <IsrLabel variant="primary" color="blue" size="md">
              Founding Partnerships
            </IsrLabel>
            {foundingPartnershipsCopy && (
              <div
                className="foundingPartnerships-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: foundingPartnershipsCopy,
                }}
              />
            )}

            <div className=" row logoToVisitContainer">
              {foundingPartnershipsComp &&
                foundingPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrPartner.picture}
                    url={item.isrPartner.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className=" row membersContainer">
              {foundingPartnershipsPeople &&
                foundingPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrPartner}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrPartner.picture !== null}
                  />
                ))}
            </div>
          </div>
          <div className="managingPartnershipsContainer">
            <IsrLabel variant="primary" color="blue" size="md">
              Managing Partnerships
            </IsrLabel>
            {managingPartnershipsCopy && (
              <div
                className="managingPartnerships-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: managingPartnershipsCopy,
                }}
              />
            )}
            <div className=" row logoToVisitContainer">
              {managingPartnershipsComp &&
                managingPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrPartner.picture}
                    url={item.isrPartner.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className=" row membersContainer">
              {managingPartnershipsPeople &&
                managingPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrPartner}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrPartner.picture !== null}
                  />
                ))}
            </div>
          </div>
          <div className="sustainingPartnershipsContainer">
            <IsrLabel variant="primary" color="blue" size="md">
              Sustaining Partnerships
            </IsrLabel>
            {sustainingPartnershipsCopy && (
              <div
                className="sustainingPartnerships-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sustainingPartnershipsCopy,
                }}
              />
            )}

            <div className=" row logoToVisitContainer">
              {sustainingPartnershipsComp &&
                sustainingPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrPartner.picture}
                    url={item.isrPartner.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className=" row membersContainer">
              {sustainingPartnershipsPeople &&
                sustainingPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrPartner}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrPartner.picture !== null}
                  />
                ))}
            </div>
          </div> */}

          <Spacer size={90} mobileSize={40} />
        </div>
      </section>
      <BioModal
        toggleModal={() => {
          setModalOpen(false);
        }}
        showModal={bioModalOpen}
        modalData={selectedMemberToRead}
      />
    </>
  );
};

export default PartnersSection;
